<template>
	<div id="wrapper" class="start-intermission"
		:class="(bgColor) ? 'color theme-' + bgColor : null"
		:style="
			(bgImageURL) ? 'background-image: url(' + bgImageURL + ');' :
			(bgUploadURL) ? 'background-image: url(' + bgUploadURL + ');' :
			null"
		>
			<div class="error-noSessionFound">
			<div class="branding">
				<h1 class="logo">
					<a href="/">
						<img src="./../assets/logo.png" alt="Intermission logo | Hosting long meetings? Take a break."/>
					</a>
				</h1>
			</div>

			<div class="content">			
				<div class="col1">
					<el-divider content-position="left">Error: 404</el-divider>
					<h3>Uh-oh! This page doesn't look right.</h3>
					<p>If you were looking for a session, here's hoping your pals will let you know when the intermission ends!</p>
					<p>Or better yet, you can verify the link from the host and try again.</p>
				</div>
				<div class="col2">
					<span class="credit">
						<span>Photo by</span><br />
						<a href="https://unsplash.com/@jamie452?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Jamie Street</a> on <a href="https://unsplash.com/s/photos/dog-confused?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
					</span>
					<img src="../assets/img/error.jpg" />
				</div>
			</div>
		</div>
	</div>
</template>