import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import VueFullscreen from 'vue-fullscreen'
import Unsplash from 'vue-unsplash'
import { createRouter, createWebHistory } from 'vue-router'

import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/dist/index.css'

import './assets/styles/app.scss'

import ConfigIntermission from './components/ConfigIntermission.vue'
import StartIntermission from './components/StartIntermission.vue'
import PageNotFound from './components/PageNotFound.vue'

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            name: 'home',
            path: '/',
            component: ConfigIntermission,
            props: true
        },
        {
            name: 'startIntermissiom',
            path: '/session/:sessionID',
            component: StartIntermission,
            props: true
        },
        {
            path: '/404',
            name: 'PageNotExist',
            component: PageNotFound,
          },
          {
            path: "/:catchAll(.*)", // Unrecognized path automatically matches 404
            redirect: '/404',
          },
    ]
});

const app = createApp(App)
    .use(store)
    .use(router)
    .use(VueFullscreen)
    .use(Unsplash, { accessKey: 'MSV8EzQnCk7hPgXSw3wUMRFwY6PxMlhmRVDjtBAo2XY' })
    .use(ElementPlus)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.mount('#app')