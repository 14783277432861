<template>
	<div id="wrapper" class="config-intermission"
	:class="(bgColor && !bgImageURL && !bgUploadURL) ? 'color theme-' + bgColor : null"
	:style="
		(bgImageIndex != null) ? 'background-image: url(' + bgImageURL + ');' :
		(bgUploadURL) ? 'background-image: url(' + bgUploadURL + ');' :
		null"
	>
		<div id="sidebar">
			<div class="branding">
				<h1 class="logo">
					<a href="/">
						<img src="./../assets/logo.png" alt="Intermission logo | Hosting long meetings? Take a break."/>
					</a>
				</h1>
			</div>

			<div class="sidebar-content">
				<el-collapse v-model="activeName" @change="handleChange">
					<el-steps direction="vertical">
						<div id="config">
							<el-divider content-position="left">⚡ Quick start</el-divider>
							<section class="invisible">
									<!-- <small style="margin-top: 0">⚡ Quick start</small> -->
									<div class="shortcut-list">
										<a class="el-button el-button--small" @click="configIntermission(5)" target="child">5 m</a>
										<a class="el-button el-button--small" @click="configIntermission(10)" target="child">10 m</a>
										<a class="el-button el-button--small" @click="configIntermission(15)" target="child">15 m</a>
										<a class="el-button el-button--small" @click="configIntermission(30)" target="child">30 m</a>
										<a class="el-button el-button--small" @click="configIntermission(60)" target="child">1 h</a>
									</div>
							</section>

							<el-divider content-position="left">Change it up</el-divider>

							<section class="invisibles">
								<h2 style="margin-bottom: 1em;"><el-step status="wait" />
									Return back in
									<span>{{calculateRemainingTime(timer)}}</span>
								</h2>
								<el-slider v-model="timer" :min="1" :max="120" :step="1" :marks="marks" :format-tooltip="calculateRemainingTime"/>
							</section>

							<!-- <h3>Advanced</h3> -->

							<section class="invisibles">
								<h2 style="margin-bottom: 1em;"><el-step status="wait" />Message</h2>
								<textarea class="messageInput" v-model="message" placeholder="We will be back in $time" rows="3"></textarea>
								<small>Use <code>$time</code> to insert time</small>
							</section>

							<section class="invisibles background-selection">
								<el-collapse-item name="3">
									<template #title>
										<h2><el-step status="wait" />Background</h2>
									</template>

									<section class="invisible backdrop-deck">
										<el-tabs v-model="backdropActive" class="backdrop-tabs">

											<el-tab-pane label="Colors" name="colors">
												<div class="section">
													<input type="radio" v-model="bgColor" :value="colorPalette[itemObjKey]" :style="{'background-color': '#'+colorPalette[itemObjKey], 'border-color': '#'+colorPalette[itemObjKey]}" class="backdrop-item backdrop-color" :class="{active: bgColor === colorPalette[itemObjKey]}" v-for="(color, itemObjKey) in colorPalette" :key="itemObjKey"/>
												</div>
											</el-tab-pane>

											<el-tab-pane label="Photos" name="photos">
												<div class="section">
													<input type="radio" v-model="bgImageIndex" :value="itemObjKey" :style="{'background-image': 'url('+ unSplashObj[itemObjKey].urls.thumb +')'}" class="backdrop-item backdrop-bg" :class="{ active: bgImageIndex === itemObjKey }" v-for="(color, itemObjKey) in unSplashObj" :key="itemObjKey"/>
												</div>
												<el-switch v-model="bgScreensaver" style="--el-switch-off-color: #c7d3e2; margin-top: 10px;" active-text="Change photo every 15 mins" @change="toggleScreensaver"/>
											</el-tab-pane>

											<el-tab-pane label="Upload" name="upload">
												<div class="upload-drag" :class="(bgDropZoneState) ? 'active' : null" @dragenter.prevent="setActive" @dragover.prevent="setActive" @dragleave.prevent="setInactive" @drop.prevent="onDrop">
													<input type="file" id="bgImage" @change="bgUploadClick" style="display: none;"/>
													<div class="upload-drag-inner" @drop="bgUploadDrag">
														<el-icon class="el-icon--upload"><upload-filled /></el-icon>
														<div class="el-upload__text">
															<label for="bgImage">Drop file here or <span> click to upload</span></label>
															<div class="el-upload__tip">Supported formats: jpg, png, gif</div>
														</div>
													</div>
												</div>
												<div class="bgUpload-preview" v-if="bgUploadName">
													<div class="left-group">
														<img :src="bgUploadURL" />
														<span>{{bgUploadName}}</span>
													</div>
													<div class="right-group">
														<el-icon class="clear" @click="bgUploadClear"><CircleCloseFilled /></el-icon>
													</div>
												</div>
											</el-tab-pane>

										</el-tabs>
									</section>
								</el-collapse-item>
							</section>

							<section class="invisible config-foot">
								<!-- <router-link class="el-button el-button--primary" :to="{ name: 'startIntermissiom', params: {session: this.sessionID} }" @click="configIntermission()" target="child">Start timer ({{calculateRemainingTime(timer)}})</router-link> -->
								<button class="el-button el-button--primary" @click="configIntermission(timer)" target="child">Start timer ({{calculateRemainingTime(timer)}})</button>
							</section>
						</div>
					</el-steps>
				</el-collapse>
				<footer>
					<el-divider />
					<small>
						Intermission • <a href="https://twitter.com/JasrajSuri" target="_blank">@JasrajSuri</a>
					</small>
				</footer>
			</div>
		</div>

		<div class="wave" v-if="(bgColor && !bgImageURL && !bgUploadURL)"></div>

	</div>
</template>

<script>
	import jQuery from '../../node_modules/jquery/dist/jquery';
	window.jQuery = jQuery
	require('../../node_modules/highlight-within-textarea/jquery.highlight-within-textarea');

	export default {
		data() {
			return {
				config: {},
				bgDropZoneState: false,
				photosTabLoaded: false,
				backdropActive: 'colors',
				activeName: "2",
				timer: 30,
				marks: {
					30: '30 mins',
					60: '1 hr',
					90: '1.5 hrs',
				},
				countdown: null,
				message: "We will be back in $time",
				uploadLogo: false,
				logo: [],
				fileList: [],
				logoAlignment: 0,
				unSplashObj: null,
				bgChoice: 1,
				bgScreensaver: false,
				screenSaverTimer: '',
				bgColor: "4b55a5",
				bgImageIndex: null,
				bgImageURL: null,
				bgImageFirstName: null,
				bgImageLastName: null,
				bgImagePortfolio: null,
				bgUploadURL: null,
				colorPalette: [
					"5dd3bc",
					"16a085",
					"a1d2f3",
					"40acf4",
					"4b55a5",
					"f3aacd",
					"c283dc",
					"6c2789",
					"d0b23c",
					"df995b",
					"bc8d63",
					"d03e2e",
					"222222",
					"7f8c8d",
					"d6d6d6",
				],
				dragAndDropCapable: false,
				files: [],
				openNewTab: false,
				openNewWindow: false,
				unsplash: {
					accessKey: "MSV8EzQnCk7hPgXSw3wUMRFwY6PxMlhmRVDjtBAo2XY",
					portfolio: '',
					firstName: '',
					image: "",
					lastName: '',
					url: "https://api.unsplash.com/photos/random",
				},
			}
		},
		
		watch: {
			backdropActive() {
				if(this.backdropActive === "photos" && !this.photosTabLoaded) {
					this.fetchRandomPhoto();
					this.photosTabLoaded = true;
				}	
			},

			// Resetting the bgcolor/bgimage models, so only one exists
			// at any given time - whatever was selected the last.
			bgColor() {
				if(this.bgColor) {
					this.bgImageURL = null;
					// this.bgUpload.url = null;
				}
			},

			bgImageIndex() {
				if(this.bgImageIndex !== null) {
					this.bgImageURL = this.unSplashObj[this.bgImageIndex].urls.full;
					this.bgImageFirstName = this.unSplashObj[this.bgImageIndex].user.first_name;
					this.bgImageLastName = this.unSplashObj[this.bgImageIndex].user.last_name;
					this.bgImagePortfolio = this.unSplashObj[this.bgImageIndex].user.portfolio_url;
					this.bgColor = null;
					// this.bgUpload.url = null;
				}
			},

			bgUpload() {
				if(this.bgUpload.url) {
					this.bgColor = null;
					this.bgImageURL = null;
				}
			},
		},

		methods: {
            toggleScreensaver(e) {
				this.bgScreensaver = e;
			},

			setActive() {
				this.bgDropZoneState = true
			},

			setInactive() {
				this.bgDropZoneState = false
			},

			onDrop() {
				this.setInactive()
			},

			bgUploadClick(e) {
				if (e.target.files[0].type.match(this.supportedImageType)) {
					this.bgUploadName = e.target.files[0].name;
					this.bgUploadURL = window.URL.createObjectURL(e.target.files[0]);
				}
			},

			bgUploadDrag(e) {
				if (e.dataTransfer.files[0].type.match(this.supportedImageType)) {
					this.bgUploadName = e.dataTransfer.files[0].name;
					this.bgUploadURL = window.URL.createObjectURL(e.dataTransfer.files[0]);
				}
			},

			bgUploadClear() {
				this.bgUpload = [];
				this.bgColor = '4b55a5';
			},

			handleLogoUploaderExceed(file) {
				this.$refs.ImageUploader.clearFiles()
				this.$refs.ImageUploader.handleStart(file[0])
			},

			fetchRandomPhoto() {
				fetch( this.unsplash.url + `?client_id=${this.unsplash.accessKey}&amp;orientation=landscape&amp;count=15&amp;collections=Km-m37g3uDU`)
					.then(response => response.json())
				.then((json) => {
					this.unSplashObj = json;
				})
				.catch(() => {
				})
			},

			calculateRemainingTime(val) {
				let hourCount = parseInt(val / 60, 10);
				let minuteCount = parseInt(val % 60, 10);

				if(val === 1)
					return minuteCount + ' min';
				
				if(val > 1 && val <= 59)
					return minuteCount + ' mins';
				
				else if(val > 59)
					if(minuteCount === 0)
						return hourCount + ' hr';

					if(val < 119)
						if(minuteCount === 1)
							return hourCount + ' hr ' + minuteCount + ' min';
						else
							return hourCount + ' hr ' + minuteCount + ' mins';

					else
						if(minuteCount === 0)
							return hourCount + ' hrs';
						if(minuteCount === 1)
							return hourCount + ' hrs ' + minuteCount + ' min';
						else
							return hourCount + ' hrs ' + minuteCount + ' mins';
			},
			
			async saveSessionToDBandLocalStorage(config) {
				try {
					fetch('/.netlify/functions/saveSessionToFaunaScript', {
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify({
							config,
						}),
					})
					.then(
						(response) => response.json()
						)
					.then(
						(sessionConfig) => {
							// retrieve existing ID set from local storage (if available), or create an empty array
							// var localStorageTemp = JSON.parse(localStorage.getItem('IntermissionSsessionConfigIDs')) || [];
							// console.log(configRes);
							// console.log(localStorageTemp);
							
							// Add currentID to array
							// localStorageTemp.push({
							// 	ID: configRes.response.ref['@ref'].id,
							// 	expireAt: configRes.response.data.expireAt['@ts']
							// });
							
							// Store array back into local storage
							// window.localStorage.setItem("IntermissionSsessionConfigIDs", JSON.stringify(localStorageTemp));

							// var localStorageTemp = [];
							
							
							// Store sessionID
							var sessionID = sessionConfig.response.ref['@ref'].id;
							// Calculate cookie expiration date
							var cookieExpiresAt = new Date(new Date(sessionConfig.response.data.expiresAt['@ts']).getTime() + 60 * 60 * 24 * 1000).toUTCString();
							// Create cookie
							document.cookie = sessionID + "=true; SameSite=Lax; path=/; expires=" + cookieExpiresAt;
							
							config.firstRun = true;
							config.sessionID = sessionID;
							config.expiresAt = new Date(sessionConfig.response.data.expiresAt['@ts']).getTime();

							// Store array back into local storage
							window.localStorage.setItem("intermission-session-id-" + sessionConfig.response.ref['@ref'].id, JSON.stringify(config));

							// Redirect to startIntermission
							this.$router.push({ name: 'startIntermissiom', params: { sessionID: sessionID } })
						}
					);
				}

				catch (err) {
					console.log(err);
				}

			},
			
			configIntermission: function(timer) {
				this.timer = timer
				this.config.timer = timer;
				this.config.message = this.message;

				if(this.bgImageIndex != null) {
					let unSplashURL = (this.unSplashObj[this.bgImageIndex].urls.full) ? this.unSplashObj[this.bgImageIndex].urls.full : null;
					let unSplashFirstName = (this.unSplashObj[this.bgImageIndex].user.first_name) ? this.unSplashObj[this.bgImageIndex].user.first_name : null;
					let unSplashLastName = (this.unSplashObj[this.bgImageIndex].user.last_name) ? this.unSplashObj[this.bgImageIndex].user.last_name : null;
					let unSplashPortfolio = (this.unSplashObj[this.bgImageIndex].user.portfolio_url) ? this.unSplashObj[this.bgImageIndex].user.portfolio_url : null;

					this.config.bgImageURL = unSplashURL;
					this.config.bgImageFirstName = unSplashFirstName;
					this.config.bgImageLastName = unSplashLastName;
					this.config.bgImagePortfolio = unSplashPortfolio;
				} else {
					this.config.bgImageURL = null;
					this.config.bgImageFirstName = null;
					this.config.bgImageLastName = null;
					this.config.bgImagePortfolio = null;
				}

				if(this.bgColor) {
					this.config.bgColor = (this.bgColor) ? this.bgColor : null;
				}

				if(this.bgUploadURL) {
					this.config.bgUploadURL = this.bgUploadURL;
				}

				this.config.bgScreensaver = this.bgScreensaver;
				
				// Store in FaunaDB
				this.saveSessionToDBandLocalStorage(this.config);
			},

			saveBgImage: function(e) {
				this.bgUpload = [];
				
				let fileListBgImage = Array.prototype.slice.call(e.target.files);

				fileListBgImage.forEach(l => {
					if(!l.type.match("image.*")) {
						return;
					}

					let readerBgImage = new FileReader();
					let that = this;

					readerBgImage.onload = function (e) {
						that.bgUpload.push(e.target.result);
					}
					readerBgImage.readAsDataURL(l);
					this.config.bgUpload = that.bgUpload;
				});
			},

			clearBgImage: function() {
				this.bgUpload = '';
				this.config.bgUpload = '';
			},
		},

		created() {
			this.config.message = '';
			this.config.bgImage = '';
			this.config.bgColor = '';
		},

		computed() {
			jQuery('.messageInput').highlightWithinTextarea('update');
		},

		mounted() {
			jQuery('.messageInput').highlightWithinTextarea({
				highlight: '$time' // string, regexp, array, function, or custom object
			});
		}
	}

</script>