<template>
	<div id="wrapper" class="start-intermission"
		:class="(bgColor) ? 'color theme-' + bgColor : null"
		:style="
			(bgImageURL) ? 'background-image: url(' + bgImageURL + ');' :
			(bgUploadURL) ? 'background-image: url(' + bgUploadURL + ');' :
			null"
		>
		<div class="wave" v-if="(bgColor && !bgImageURL && !bgUploadURL && !sessionConfig.noData)"></div>

		<div class="content" v-if="!sessionConfig.noData">
			<div class="-content-top" :class="(bgImageURL || bgUploadURL) ? '-shadow' : null">
				<h1>{{message}}</h1>
				<h1 class="time">

					<span class="time-box">
						{{hourCount.toString().padStart(2, '0')}}
					</span>
					<span>:</span>

					<span class="time-box">
						{{minuteCount.toString().padStart(2, '0')}}
					</span>
					<span>:</span>

					<span class="time-box">
						{{secondCount.toString().padStart(2, '0')}}
					</span>

					<span class="time-label">Hours</span>
					<span></span>
					<span class="time-label">Minutes</span>
					<span></span>
					<span class="time-label">Seconds</span>
				</h1>
			</div>

			<div class="-content-bottom" :class="(bgImageURL) ? '-shadow' : null">
				<p class="credit" v-if="(bgImageURL)"><span>Photo by</span><br/>
					<a :href='bgImagePortfolio' target="_blank">
						{{bgImageFirstName}} {{bgImageLastName}}
						<svg style="position: relative; top: 2px;" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" width="16" height="16">
							<path d="
								M853.333333 469.333333a42.666667 42.666667 0 0 0-42.666666
								42.666667v256a42.666667 42.666667 0 0 1-42.666667 42.666667H256a42.666667
								42.666667 0 0 1-42.666667-42.666667V256a42.666667
								42.666667 0 0 1 42.666667-42.666667h256a42.666667 42.666667
								0 0 0 0-85.333333H256a128 128 0 0 0-128 128v512a128 128
								0 0 0 128 128h512a128 128 0 0 0 128-128v-256a42.666667
								42.666667 0 0 0-42.666667-42.666667z
								" fill="currentColor">
							</path>
							<path d="
								M682.666667 213.333333h67.413333l-268.373333 267.946667a42.666667
								42.666667 0 0 0 0 60.586667 42.666667 42.666667
								0 0 0 60.586666 0L810.666667 273.92V341.333333a42.666667
								42.666667 0 0 0 42.666666 42.666667 42.666667 42.666667 0 0 0
								42.666667-42.666667V170.666667a42.666667 42.666667 0 0
								0-42.666667-42.666667h-170.666666a42.666667
								42.666667 0 0 0 0 85.333333z
								" fill="currentColor">
							</path>
						</svg>
					</a>
				</p>

				<div class="toolbar" v-if="isHost">
					<div class="left-group">
						<el-popover placement="top" :width="500" trigger="click">
							<template #reference>
								<el-button round>
									<el-icon class="el-icon--left"><Picture /></el-icon>
									Background
								</el-button>
							</template>

							<section class="invisible backdrop-deck">

								<el-tabs v-model="backdropActive" class="backdrop-tabs">

									<el-tab-pane label="Colors" name="colors">
										<div class="section">
											<input type="radio" v-model="bgColor" :value="colorPalette[itemObjKey]" :style="{'background-color': '#'+colorPalette[itemObjKey], 'border-color': '#'+colorPalette[itemObjKey]}" class="backdrop-item backdrop-color" :class="{active: bgColor === colorPalette[itemObjKey]}" v-for="(color, itemObjKey) in colorPalette" :key="itemObjKey"/>
										</div>
									</el-tab-pane>

									<el-tab-pane label="Photos" name="photos">
										<div class="section">
											<input type="radio" v-model="bgImageIndex" :value="itemObjKey" :style="{'background-image': 'url('+ unSplashObj[itemObjKey].urls.thumb +')'}" class="backdrop-item backdrop-bg" :class="{ active: bgImageIndex === itemObjKey }" v-for="(color, itemObjKey) in unSplashObj" :key="itemObjKey"/>
										</div>
										<el-switch v-model="bgScreensaver" style="--el-switch-off-color: #c7d3e2; margin-top: 10px;" active-text="Change photo every 15 mins" @change="toggleScreensaver"/>
									</el-tab-pane>

									<el-tab-pane label="Upload" name="upload">
										<div class="upload-drag" :class="(bgDropZoneState) ? 'active' : null" @dragenter.prevent="setActive" @dragover.prevent="setActive" @dragleave.prevent="setInactive" @drop.prevent="onDrop">
											<input type="file" id="bgImage" @change="bgUploadClick" style="display: none;" accept="image/png, image/gif, image/jpeg"/>
											<div class="upload-drag-inner" @drop="bgUploadDrag">
												<el-icon class="el-icon--upload"><upload-filled /></el-icon>
												<div class="el-upload__text">
													<label for="bgImage">Drop file here or <span> click to upload</span></label>
													<div class="el-upload__tip">Supported formats: jpg, png, gif</div>
												</div>
											</div>
										</div>
										<div class="bgUpload-preview" v-if="bgUploadURL">
											<div class="left-group">
												<img :src="bgUploadURL" />
												<span>{{bgUploadName}}</span>
											</div>
											<div class="right-group">
												<el-icon class="clear" @click="bgUploadClear"><CircleCloseFilled /></el-icon>
											</div>
										</div>
									</el-tab-pane>

								</el-tabs>
								
							</section>
						</el-popover>
					</div>
					<div class="right-group">
						<el-tooltip
							class="box-item"
							effect="dark"
							content="Copy session link"
							placement="top"
						>
							<el-button round>
								<el-icon style="vertical-align: middle">
									<Link />
								</el-icon>
							</el-button>
						</el-tooltip>
						<el-tooltip
							class="box-item"
							effect="dark"
							content="Restart session"
							placement="top"
						>
							<el-button round @click="restartTimer">
								<el-icon style="vertical-align: middle">
									<RefreshLeft />
								</el-icon>
							</el-button>
						</el-tooltip>
						<a class="end-intermission" @click="exitIntermission()">End Intermission</a>
					</div>
				</div>
			</div>
		</div>

		<div v-if="sessionConfig.noData" class="error-noSessionFound">
			<div class="branding">
				<h1 class="logo">
					<a href="/">
						<img src="./../assets/logo.png" alt="Intermission logo | Hosting long meetings? Take a break."/>
					</a>
				</h1>
			</div>

			<div class="content">			
				<div class="col1">
					<el-divider content-position="left">Error: 404</el-divider>
					<h3>Aaaawkward!<br />I don't see this session in my records.</h3>
					<p>Let's hope your pals let you know when the intermission ends!<br />Or better yet, you can verify the link from the host and try again.</p>
				</div>
				<div class="col2">
					<span class="credit">
						<span>Photo by</span><br />
						<a href="https://unsplash.com/@jamie452?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Jamie Street</a> on <a href="https://unsplash.com/s/photos/dog-confused?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
					</span>
					<img src="../assets/img/error.jpg" />
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import { Howl } from 'howler';

	export default {
		data() {
			return {
				sessionConfig: {},
				sessionID: this.$route.params.sessionID,
				isHost: false,
				firstRun: false,
				supportedImageType: /image.*/,
				bgDropZoneState: false,
				delayStart: true,
				delayedStartTime: 3,
				backdropActive: 'colors',
				photosTabLoaded: false,
				colorPalette: [
					"5dd3bc",
					"16a085",
					"a1d2f3",
					"40acf4",
					"4b55a5",
					"f3aacd",
					"c283dc",
					"6c2789",
					"d0b23c",
					"df995b",
					"bc8d63",
					"d03e2e",
					"222222",
					"7f8c8d",
					"d6d6d6",
				],
				unSplashObj: null,				

				displayTime: "",
				hourCount: '',
				minuteCount: '',
				secondCount: '',
				hourComplete: false,
				minuteComplete: false,
				secondComplete: false,

				timer: null,
				expiresAt: null,
				expireCookieAt: null,
				countdownTime: null,
				message: null,
				// variety of background vars
				bgImageIndex: null,
				bgImageURL: null,
				bgImageFirstName: null,
				bgImageLastName: null,
				bgImagePortfolio: null,
				bgColor: null,
				bgUploadURL: null,
				bgScreensaver: null,


				unsplash: {
					accessKey: "MSV8EzQnCk7hPgXSw3wUMRFwY6PxMlhmRVDjtBAo2XY",
					portfolio: '',
					firstName: '',
					image: "",
					lastName: '',
					url: "https://api.unsplash.com/photos/random",
				},
			}
		},

		watch: {
			backdropActive() {
				if(this.backdropActive === "photos" && !this.photosTabLoaded) {
					this.fetchRandomPhoto();
					this.photosTabLoaded = true;
				}	
			},

			// Resetting the bgcolor/bgimage models, so only one exists
			// at any given time - whatever was selected the last.
			bgColor() {
				if(this.bgColor) {
					clearInterval(this.screensaverTimer);

					this.bgImageIndex = null;

					this.bgImageURL = null;

					this.bgUploadURL = null;
				}
			},

			bgImageIndex() {
				if(this.bgImageIndex != null) {
					this.bgImageURL = this.unSplashObj[this.bgImageIndex].urls.full;
					this.bgImageFirstName = this.unSplashObj[this.bgImageIndex].user.first_name;
					this.bgImageLastName = this.unSplashObj[this.bgImageIndex].user.last_name;
					this.bgImagePortfolio = this.unSplashObj[this.bgImageIndex].user.portfolio_url;
					this.bgColor = null;
					this.bgUploadURL = null;

					if(this.bgScreensaver) {
						clearInterval(this.screensaverTimer);
						this.screensaverTimer = setInterval(this.screensaver, 300000);
					}
				}
			},

			bgUploadURL() {
				if(this.bgUploadURL) {
					clearInterval(this.screensaverTimer);
					this.bgColor = null;
					this.bgImageURL = null;
				}
			},
		},

		methods: {
			async readExistingSession() {
				let response = await fetch('/.netlify/functions/readSessionFromFaunaScript', {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: this.sessionID,
				})
				return await response.json()
				// .then(
				// 	(response) => response.json()
				// )
				// .then(
				// 	(configRes) => {
				// 		return configRes;
				// 	}
				// );
			},
				// 	// // Store transaction ID in config object
				// 	// this.config.sessionID = config.response.data.id;

				// 	// // Store in local storage
				// 	// window.localStorage.setItem("IntermissionSsessionConfig", JSON.stringify(this.config));

				// 	// // Redirect to startIntermission
				// 	// this.$router.push({ name: 'startIntermissiom', params: { session: this.config.sessionID } })
				// 	}
				// );

				// if(localStorage.getItem("sessionConfig") != null) {
				// 	console.log('reading localstorage');
				// 	// Since object exists in local storage,
				// 	// set this system as host for this session
				// 	this.isHost = true;

				// 	// Read local storage for data
				// 	this.sessionConfig = JSON.parse(window.localStorage.getItem('sessionConfig'));

				// 	// Store data items that need to be manipulated into variables.
				// 	// The rest can be referenced directly from local storage.
				// 	this.message = (this.sessionConfig.message) ? this.sessionConfig.message : 'We will be back soon';
				// 	this.countdownTime = this.sessionConfig.timer;

				// 	console.log(this.sessionConfig);
					
				// 	// // Store in respective vars (not a critical step,
				// 	// // but simple variables are easier to work with!)
				// 	// // variety of background vars
				// 	// this.bgImageURL = this.sessionConfig.bgImageURL;
				// 	// this.bgImageFirstName = this.sessionConfig.bgImageFirstName;
				// 	// this.bgImageLastName = this.sessionConfig.bgImageLastName;
				// 	// this.bgImagePortfolio = this.sessionConfig.bgImagePortfolio;
				// 	// this.bgColor = this.sessionConfig.bgColor;
				// 	// this.bgUploadURL = this.sessionConfig.bgUploadURL;
				// 	// this.bgScreensaver = (this.sessionConfig.bgScreensaver) ? this.sessionConfig.bgScreensaver : false;
				// }
				
				// else {
				// 	console.log("going for the db!")
				// 	// try {
				// 	// 	fetch('/.netlify/functions/readSessionFromFaunaScript', {
				// 	// 		method: "POST",
				// 	// 		headers: {
				// 	// 			"Content-Type": "application/json",
				// 	// 		},
				// 	// 		body: JSON.stringify({
				// 	// 			data,
				// 	// 		}),
				// 	// 	})
				// 	// 	.then((response) => response.json())
				// 	// }

				// 	// catch (err) {
				// 	// 	console.log(err);
				// 	// }
				// }

			toggleScreensaver(e) {
				this.bgScreensaver = e;
				if(this.bgScreensaver) {
					clearInterval(this.screensaverTimer);
					this.screensaverTimer = setInterval(this.screensaver, 300000);
				} else {
					clearInterval(this.screensaverTimer);
				}
			},
			
			screensaver() {
				if(this.bgImageIndex != null) {
					if(this.bgImageIndex <= 13) {
						this.bgImageIndex = this.bgImageIndex + 1;

						if(this.bgImageIndex == 14) {
							this.fetchRandomPhoto();
						}
					}
					else {
						this.bgImageIndex = 0;
					}
				}
			},

			setActive() {
				this.bgDropZoneState = true
			},

			setInactive() {
				this.bgDropZoneState = false
			},

			onDrop() {
				this.setInactive()
			},
			
			bgUploadClick(e) {
				if (e.target.files[0].type.match(this.supportedImageType)) {
					this.bgUploadName = e.target.files[0].name;
					this.bgUploadURL = window.URL.createObjectURL(e.target.files[0]);
				}
			},

			bgUploadDrag(e) {
				if (e.dataTransfer.files[0].type.match(this.supportedImageType)) {
					this.bgUploadName = e.dataTransfer.files[0].name;
					this.bgUploadURL = window.URL.createObjectURL(e.dataTransfer.files[0]);
				}
			},

			bgUploadClear() {
				this.bgUploadURL = null;
				this.bgColor = '4b55a5';
			},

			fetchRandomPhoto() {
				fetch( this.unsplash.url + `?client_id=${this.unsplash.accessKey}&amp;orientation=landscape&amp;count=15&amp;collections=Km-m37g3uDU`)
					.then(response => response.json())
				.then((json) => {
					this.unSplashObj = json;
				})
				.catch(() => {
				})
			},

			delayedCountdown() {
				console.log('starting countdown' + this.delayedStartTime);
				if(this.delayedStartTime == 0) {
					clearInterval(this.delayedStartTimer);
					this.delayStart = false;
					this.startCountdown();
				} else {
					this.delayedStartTime--;
				}
			},

			startCountdown() {
				this.countdownTimer = setInterval(this.updateCountdown, 1000);
			},

			updateCountdown() {
				if (this.countdownTime > 0) {
					if (this.secondCount > 0) {
						this.secondCount--;
					}
					
					else {
						this.secondCount = 0;
						
						if (this.minuteCount > 0) {
							this.minuteCount--;
						}
						
						else if (this.hourCount > 0) {
							this.minuteCount = 59;
							this.hourCount--;
						}
					}

					this.countdownTime--;
					this.configureCountdownDetails();
				}
			},

			completeCountdown() {
				clearInterval(this.countdownTimer);

				this.secondCount = 0;
				this.minuteCount = 0;
				this.hourCount = 0;
				this.message = "Thank you for your patience. We'll start shortly."

				//Play sound
				var sound = new Howl({
					src: "audio/complete.m4a",
				});
				sound.play();
			},

			calculateRemainingTime() {
				if(this.timer == 1)
					return this.minuteCount + ' min';
				
				else if(this.timer > 1 && this.timer <= 59)
					return this.minuteCount + ' mins';
				
				else if(this.timer > 59) {
					if(this.minuteCount == 0)
						return this.hourCount + ' hr';

					if(this.timer < 119)
						if(this.minuteCount == 1)
							return this.hourCount + ' hr ' + this.minuteCount + ' min';
						else
							return this.hourCount + ' hr ' + this.minuteCount + ' mins';

					else
						if(this.minuteCount == 0)
							return this.hourCount + ' hrs';
						if(this.minuteCount == 1)
							return this.hourCount + ' hrs ' + this.minuteCount + ' min';
						else
							return this.hourCount + ' hrs ' + this.minuteCount + ' mins';
				}
			},

			restartTimer() {
				var killId = setTimeout(function() {
					for (var i = killId; i > 0; i--) clearInterval(i)
				}, 10);

				this.timer = this.sessionConfig.response.data.sessionConfig.config.timer;
				console.log("timer is: " + this.timer);

				this.message = this.sessionConfig.response.data.sessionConfig.config.message;
				this.expiresAt = new Date(this.sessionConfig.response.data.expiresAt['@ts']).getTime();

				if(this.sessionID == this.sessionConfig.response.ref['@ref'].id) {
					this.configureCountdownDetails();
					this.countdownTimer = setInterval(this.updateCountdown, 1000);
				}
			},

			async exitIntermission() {
				let response = await fetch('/.netlify/functions/deleteSessionFromFaunaScript', {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: this.sessionID,
				})
				return await response.json()
				.then(
						(configRes) => {
							var killId = setTimeout(function() {
								for (var i = killId; i > 0; i--) clearInterval(i)
							}, 10);
							
							this.$router.push({ name: 'home' })
							return configRes;
						}
				);
			},

			// async updateIntermission() {
			// 	this.config.timer = timer;
			// 	this.config.message = this.message;

			// 	if(this.bgImageIndex != null) {
			// 		let unSplashURL = (this.unSplashObj[this.bgImageIndex].urls.full) ? this.unSplashObj[this.bgImageIndex].urls.full : null;
			// 		let unSplashFirstName = (this.unSplashObj[this.bgImageIndex].user.first_name) ? this.unSplashObj[this.bgImageIndex].user.first_name : null;
			// 		let unSplashLastName = (this.unSplashObj[this.bgImageIndex].user.last_name) ? this.unSplashObj[this.bgImageIndex].user.last_name : null;
			// 		let unSplashPortfolio = (this.unSplashObj[this.bgImageIndex].user.portfolio_url) ? this.unSplashObj[this.bgImageIndex].user.portfolio_url : null;

			// 		this.config.bgImageURL = unSplashURL;
			// 		this.config.bgImageFirstName = unSplashFirstName;
			// 		this.config.bgImageLastName = unSplashLastName;
			// 		this.config.bgImagePortfolio = unSplashPortfolio;
			// 	} else {
			// 		this.config.bgImageURL = null;
			// 		this.config.bgImageFirstName = null;
			// 		this.config.bgImageLastName = null;
			// 		this.config.bgImagePortfolio = null;
			// 	}

			// 	if(this.bgColor) {
			// 		this.config.bgColor = (this.bgColor) ? this.bgColor : null;
			// 	}

			// 	if(this.bgUploadURL) {
			// 		this.config.bgUploadURL = this.bgUploadURL;
			// 	}

			// 	this.config.bgScreensaver = this.bgScreensaver;
			// },

			getCookie: function(sessionID) {
				var cookieArr = document.cookie.split(";");

				for(var i = 0; i < cookieArr.length; i++) {
					var cookiePair = cookieArr[i].split("=");
					if(sessionID == cookiePair[0].trim()) {
						return decodeURIComponent(cookiePair[1]);
					} else {
						// localStorage.clear('intermission-session-id-' + this.sessionID);
					}
				}

				return null;
			},

			// checkIfFirstRun: function(sessionID) {
				// var key = sessionID + '=';
				// var decoded_cookie = decodeURIComponent(document.cookie);
				// var carr = decoded_cookie.split(';');
				// for(var i=0; i<carr.length;i++) {
				// 	var c = carr[i];
				// 	while(c.charAt(0)==' '){
				// 		c=c.substring(1);
				// 	}
				// 	if(c.indexOf(key) == 0) {
				// 		return (c.substring(key.length, c.length) === 'false') ? true : false;
				// 	}
				// }
				// return "";

				// if
			// },
			
			configureCountdownDetails: function() {
				this.countdownTime = (this.expiresAt - new Date().getTime()) / 1000;

				if(this.countdownTime > 0) {
					this.secondCount = this.countdownTime;
					this.minuteCount = Math.floor(this.secondCount / 60);
					this.hourCount = Math.floor(this.minuteCount / 60);

					this.secondCount = Math.floor(this.secondCount % 60);
					this.minuteCount = Math.floor(this.minuteCount % 60);
				} else {
					this.completeCountdown();
				}
			},
		},

		async created() {
			var killId = setTimeout(function() {
				for (var i = killId; i > 0; i--) clearInterval(i)
			}, 10);

			// Read localstorage
			var localStorageTemp = JSON.parse(localStorage.getItem('intermission-session-id-' + this.sessionID));

			// Check if host
			if(localStorage.getItem('intermission-session-id-' + this.sessionID)) {
				// Check if host
				this.isHost = ((this.sessionID == localStorageTemp.sessionID) && this.getCookie(this.sessionID)) ? true : false;
				
				// Set firstRun to true if either execution is for host & it's the first run
				this.firstRun = ((this.isHost) && localStorageTemp.firstRun == true) ? true : null;

				if(this.firstRun) {
					console.log('first run!');
					this.message = localStorageTemp.message;
					this.timer = localStorageTemp.timer;
					this.expiresAt = localStorageTemp.expiresAt;
					this.countdownTime = localStorageTemp.timer * 60
					this.secondCount = 0;
					this.minuteCount = parseInt((this.countdownTime / 60) % 60);
					this.hourCount = parseInt((this.countdownTime / 60) / 60);
					this.bgImageURL = localStorageTemp.bgImageURL;
					this.bgImageFirstName = localStorageTemp.bgImageFirstName;
					this.bgImageLastName = localStorageTemp.bgImageLastName;
					this.bgImagePortfolio = localStorageTemp.bgImagePortfolio;
					this.bgColor = localStorageTemp.bgColor;
					this.bgUploadURL = localStorageTemp.bgUploadURL;
					this.bgScreensaver = (localStorageTemp.bgScreensaver) ? localStorageTemp : false;
					
					if(this.countdownTime > 0) {
						// Update localstorage to show firstRun completed
						localStorageTemp.firstRun = false;
						window.localStorage.setItem("intermission-session-id-" + this.sessionID, JSON.stringify(localStorageTemp));
						
						this.countdownTimer = setInterval(this.updateCountdown, 1000);
						// }
					} else {
						this.completeCountdown();
					}
				}
			}
			
			else {
				await (this.readExistingSession())
					.then(
						configResponse => {
							this.sessionConfig = configResponse;
							if(this.sessionConfig.response) {
								this.sessionConfig.noData = false;

								this.timer = this.sessionConfig.response.data.sessionConfig.config.timer;
								console.log(this.timer);

								this.message = this.sessionConfig.response.data.sessionConfig.config.message;
								
								if(this.sessionConfig.response.data.sessionConfig.config.bgColor) {
									this.bgColor = this.sessionConfig.response.data.sessionConfig.config.bgColor;
								}

								if(this.sessionConfig.response.data.sessionConfig.config.bgImageIndex) {
									this.bgColor = null;
									this.bgImageIndex = this.sessionConfig.response.data.sessionConfig.config.bgImageIndex;
									this.bgImageURL = this.sessionConfig.response.data.sessionConfig.config.bgImageURL;
									this.bgImageFirstName = this.sessionConfig.response.data.sessionConfig.config.bgImageFirstName;
									this.bgImageLastName = this.sessionConfig.response.data.sessionConfig.config.bgImageLastName;
									this.bgImagePortfolio = this.sessionConfig.response.data.sessionConfig.config.bgImagePortfolio;
								}

								this.expiresAt = new Date(this.sessionConfig.response.data.expiresAt['@ts']).getTime();

								if(this.sessionID == this.sessionConfig.response.ref['@ref'].id) {
									this.configureCountdownDetails();
									this.countdownTimer = setInterval(this.updateCountdown, 1000);
								}

								// this.secondCount = this.secondCount % 60;
								// this.minuteCount = this.minuteCount % 60;

								// if (this.countdownTime > 0) {
								// 	console.log('still going...');
								// 	this.secondCount = Math.floor(this.countdownTime / 1000);
								// 	this.minuteCount = Math.floor(this.secondCount / 60);
								// 	this.hourCount = Math.floor(this.minuteCount / 60);

								// 	this.secondCount = this.secondCount % 60;
								// 	this.minuteCount = this.minuteCount % 60;
									
								// 	console.log(new Date(this.countdownTime).getSeconds());
								// 	console.log(new Date(this.countdownTime).getMinutes());
								// 	console.log(new Date(this.countdownTime).getHours());

								// 	this.startCountdown();
								// } else {
								// 	console.log('we\'re done');
								// 	this.completeCountdown();
								// }
								// }
							} else {
								this.sessionConfig.noData = true;
								this.bgColor = '#ffffff';
							}
						}
					);
			}

			this.message = this.message.replace(/\$time/g, this.calculateRemainingTime(this.timer));

			if(!this.bgColor && !this.bgImageURL && !this.bgUpload) {
				this.bgColor = '4b55a5';
			}

			if(this.bgScreensaver && this.bgImageURL) {
				this.screensaver();
			}
		},
	};

</script>