import Vuex from "vuex";

export default new Vuex.Store({
 state: {
    intermissionConfig: {
        timer: null,
        message: null,
        // variety of background vars
        bgImageURL: null,
        bgImageFirstName: null,
        bgImageLastName: null,
        bgImagePortfolio: null,
        bgColor: null,
        bgUploadURL: null,
        bgScreensaver: null,
        fullscreen: null,
    }
 },
 getters: {},
 mutations: {},
 actions: {}
});